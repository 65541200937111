import { Alert, Div, HeaderBack } from "components";
import React, { useEffect, useState } from "react";
import { FlatList } from "react-native-gesture-handler";
import { apiInvestment } from "services/apiInvestment";
import { navigate } from "services/navigation";
import ItemProduct from "./ItemProduct";
import { useAppSelector } from "store/hooks";
import { useDispatch } from "react-redux";
import { loadProduct } from "reducer/asset";
import { RefreshControl } from "react-native";
import { Ecolors } from "constants/themes";

function ProductScreen() {
  const listProduct = useAppSelector((state) => state.asset.listProduct);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  // useEffect(() => {
  //   loadData();
  //   return () => {};
  // }, []);

  const loadData = async () => {
    try {
      dispatch(loadProduct({}));
    } catch (error: any) {
      Alert.showError({
        content: error.message,
        multilanguage: false,
      });
    }
  };

  const renderItem = ({ item, index }) => {
    return <ItemProduct data={item} />;
  };

  const ItemSeparatorComponent = () => {
    return <Div height={10} />;
  };

  const ListHeaderComponent = () => {
    return <Div height={10} />;
  };

  const ListFooterComponent = () => {
    return <Div height={100} />;
  };

  const onRefresh = () => {
    loadData();
  };

  const keyExtractor = (item, index) => `key${index}`;

  return (
    <Div screen={true}>
      <HeaderBack
        titleRight="investmentscreen.xemtaisan"
        isShowStep={true}
        onRightPress={() => {
          navigate("AssetScreen", {
            flowApp: "Product",
          });
        }}
        title="investmentscreen.sanpham"
      />
      <FlatList
        data={listProduct}
        extraData={listProduct}
        renderItem={renderItem}
        ListFooterComponent={ListFooterComponent}
        ListHeaderComponent={ListHeaderComponent}
        ItemSeparatorComponent={ItemSeparatorComponent}
        keyExtractor={keyExtractor}
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={onRefresh}
            tintColor={Ecolors.mainColor}
          />
        }
      />
    </Div>
  );
}

export default React.memo(ProductScreen);
